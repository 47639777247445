import React from 'react';
import T from 'prop-types';

import useMobileView from '../../сompetitive-intelligence-reports/shared/hooks/useMobileView';

const Slide = ({
  data,
}) => {
  const isMobileView = useMobileView();
  return (
    <div className="product-slide-content" >
      <div className="header-content">
        <h2 className="header-content-title">{data.name}</h2>
        <div className="header-content-subtitle" dangerouslySetInnerHTML={{ __html: data.description_html }} />
      </div>
      <div className="body-content">
        <div className="list-box">
          {data.features.map(item => (
            <div key={item.id} className="list-item">
              <h4 className="list-item-title">{item.name}</h4>
              <div className="list-item-subtitle" dangerouslySetInnerHTML={{ __html: item.description_html }} />
            </div>
        ))}
        </div>
        <div className="media-box">
          {data.pictures.map(picture => (
            <div key={picture.id} className="w-100">
              <img
                src={isMobileView ? (picture.picture_mobile_src || picture.picture_src) : picture.picture_src}
                alt={picture.id}
                className="media-box-image"
                loading="lazy"
              />
            </div>
        ))}
        </div>
      </div>
    </div>
  );
};

Slide.propTypes = {
  data: T.object.isRequired,
};

export default Slide;
