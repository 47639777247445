import React from 'react';
import T from 'prop-types';
import { createRoot } from 'react-dom/client';

import Slide from './Slide';
import Carousel3D from '../../../../components/Carousel3D';


const config = ({
  variableWidth: false,
  centerMode: false,
  centerPadding: '0px',
  fade: true,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        dots: true,
        arrows: true,
        centerMode: true,
      },
    },
  ],
});

const ProductSlider = ({ data }) => (
  <Carousel3D
    focusOnLoad
    className="product-hms-carousel3d"
    config={config}
    slides={data.map(slide => (
      <Slide
        key={slide.id}
        data={slide}
      />
    ))}
  />
);
ProductSlider.propTypes = {
  data: T.array,
};

export default ProductSlider;

document.addEventListener('DOMContentLoaded', () => {
  const rootNode = document.getElementById('product-spotlight-root');

  if (rootNode) {
    const slides = [];

    rootNode.querySelectorAll('.product-slide').forEach((item) => {
      const features = [];
      item.querySelectorAll('.product-feature').forEach((feature) => {
        features.push(JSON.parse(feature.dataset.feature));
      });

      const pictures = [];
      item.querySelectorAll('.picture-node').forEach((picture) => {
        pictures.push(JSON.parse(picture.dataset.picture));
      });

      const product = JSON.parse(item.dataset.product);
      slides.push({
        ...product,
        features,
        pictures: product.cover_picture_src ? [{
          id: 'cover_picture',
          picture_src: product.cover_picture_src,
          picture_mobile_src: product.cover_picture_mobile_src,
        }, ...pictures] : pictures,
      });
    });
    const root = createRoot(rootNode);

    root.render(<ProductSlider data={slides} />);
  }
});

// Slide fields
// id
// name
// description_html
// cover_picture_src
// cover_picture_mobile_src
// features
//   - id
//   - name
//   - description_html
// pictures
//   - id
//   - picture_src
//   - picture_mobile_src
